import { useEffect } from "react";

const Kids = function () {
  useEffect(() => {
    window.location.href = "https://bluenotekidsclub.com";
  }, []);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default Kids;
