import React, { useState } from 'react';
import AgeVerification from '../components/AgeVerification';

function useAgeVerification(options = {}) {
  const { minimumAge, activityId, errorMessage } = options;
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const [callbacks, setCallbacks] = useState({});

  const verifyAge = () => {
    return new Promise((resolve, reject) => {
      setOpen(true);
      setCallbacks({
        onYes: (birthday) => {
          resolve(birthday);
          close();
        },
        onNo: (birthday) => {
          reject(birthday);
        }
      });
    });
  };

  const ageVerificationElement = open ? (
    <AgeVerification
      activityId={activityId}
      minimumAge={minimumAge}
      onSuccess={callbacks.onYes}
      onFailure={callbacks.onNo}
      errorMessage={errorMessage}
    />
  ) : null;

  return [verifyAge, ageVerificationElement];
}

export default useAgeVerification;
