import React, { useContext, useEffect } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { get } from "../../services/utils";
import "./NavigationHeader.scss";
import BluesSvg from "../../assets/images/Bluenatics_Logo_2024.svg";
import NewSvg from "../../assets/images/new.svg";
import MainContext from "../../contexts/MainContext";

function NavigationHeader(props) {
  const { path } = props;
  const { listSubscription, listSubscriptionUpdate } = useContext(MainContext);

  const offersClasses = classnames("NavigationHeader-links__link", {
    "NavigationHeader-links__link--active": path === "/offers",
    "NavigationHeader-links__link--unread":
      get(listSubscription, "new_offers") && path !== "/offers",
  });

  // manually switch new_offers to false after visiting offers page
  useEffect(() => {
    if (path === "/offers" && get(listSubscription, "new_offers")) {
      listSubscriptionUpdate(listSubscription.id, {
        list_subscription: {
          ...listSubscription,
          new_offers: false,
        },
      });
    }
  }, [path]);

  return (
    <div className="NavigationHeader">
      <Link to="/">
        <img src={BluesSvg} alt="" className="NavigationHeader__logo" />
      </Link>
      <div className="NavigationHeader-links">
        <Link
          to="/"
          className={`NavigationHeader-links__link ${
            path === "/" ? "NavigationHeader-links__link--active" : ""
          }`}
        >
          Home
        </Link>
        {/* <Link to="/account" className={`NavigationHeader-links__link ${path === '/account' ? 'NavigationHeader-links__link--active': ''}`}>Account</Link> */}
        <Link
          to="/games"
          className={`NavigationHeader-links__link relative ${
            path === "/games" ? "NavigationHeader-links__link--active" : ""
          }`}
        >
          My Games
          <img
            src={NewSvg}
            className="absolute text-xs top-0 sm:top-1 -right-2 sm:right-2 h-6 w-6"
          />
        </Link>
        <a
          href="https://bluenotekidsclub.com"
          className="NavigationHeader-links__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ color: "#8ed343" }}>K</span>
          <span style={{ color: "#fbe93c" }}>I</span>
          <span style={{ color: "#4184d5" }}>D</span>
          <span style={{ color: "#d34142" }}>S</span>{" "}
          {/* <img
            src={NewSvg}
            className="absolute text-xs top-0 -right-2 sm:right-1 h-6 w-6"
          /> */}
        </a>
        <Link to="/offers" className={offersClasses}>
          Offers
        </Link>
        <Link
          to="/bars"
          className={`NavigationHeader-links__link relative ${
            path === "/bars" ? "NavigationHeader-links__link--active" : ""
          }`}
        >
          Bars
        </Link>
      </div>
    </div>
  );
}

export default NavigationHeader;
