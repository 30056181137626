import React, { useMemo, useState, Fragment } from 'react';
import NumericInput from 'react-numeric-input';
import { DateTime } from 'luxon';
import Dropdown from 'react-dropdown';
import './AgeVerification.scss';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const DATE_FORMAT = 'd/MMMM/yyyy';

function AgeVerification(props) {
  const {
    activityId,
    minimumAge,
    onSuccess,
    onFailure,
    errorMessage = `Sorry, you must be ${minimumAge} or older to view this activity`,
  } = props;
  const [month, setMonth] = useState(MONTHS[0]);
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [isNotMinAge, setIsNotMinAge] = useState(false);

  const focusNext = (selector) => {
    const nextSibling = document.querySelector(
      `.AgeVerification-InnerContainer-inputs input[name=ageverification-${activityId}-${selector}]`
    );
    if (nextSibling !== null) {
      setTimeout(() => {
        nextSibling.focus();
      }, 0);
    }
  };

  const onMonthChange = (e) => {
    setMonth(e.value);
    focusNext('day');
  };
  const onDayChange = (e) => {
    setDay(e.target.value);
    if ((e.target.value + '').length === 2) {
      focusNext('year');
    }
  };
  const onYearChange = (e) => setYear(e);

  const birthdayValid = useMemo(() => {
    const birthday = DateTime.fromFormat(
      `${day}/${month}/${year}`,
      DATE_FORMAT
    );
    return birthday.isValid;
  }, [month, day, year]);

  const handleVerify = () => {
    const birthday = DateTime.fromFormat(
      `${day}/${month}/${year}`,
      DATE_FORMAT
    );
    // diff is negative value (ex. -31.123 years)
    if (birthday.diffNow('years').years <= -minimumAge) {
      onSuccess(birthday.toISODate());
    } else {
      onFailure(birthday.toISODate());
      setIsNotMinAge(true);
    }
  };

  return (
    <div className="AgeVerification">
      <div className="AgeVerification-InnerContainer">
        {isNotMinAge ? (
          <Fragment>
            <div className="AgeVerification-InnerContainer--invalid-message">
              {errorMessage}
            </div>
          </Fragment>
        ) : (
          <div className="AgeVerification-InnerContainer-maxwidth">
            <h2 className="AgeVerification-InnerContainer--title">
              Age Verification
            </h2>
            <p className="AgeVerification-InnerContainer--subtitle">
              This activity requires you to be <span>{minimumAge}+</span> years
              or older to enter. Please enter your date of birth below to
              continue.
            </p>
            <div className="AgeVerification-InnerContainer-inputs">
              <Dropdown
                options={MONTHS}
                value={month}
                tabIndex={0}
                onChange={onMonthChange}
              />
              <input
                type="tel"
                value={day}
                placeholder="01"
                tabIndex={0}
                className="AgeVerification-InnerContainer-inputs--day"
                name={`ageverification-${activityId}-day`}
                onChange={onDayChange}
              />
              <NumericInput
                min={1900}
                max={new Date().getFullYear()}
                placeholder="YYYY"
                value={year}
                tabIndex={0}
                className="AgeVerification-InnerContainer-inputs--year"
                name={`ageverification-${activityId}-year`}
                onChange={onYearChange}
              />
            </div>
            <button
              className="btn btn--yellow btn--stretch"
              disabled={!birthdayValid}
              onClick={handleVerify}
            >
              VERIFY
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AgeVerification;
