import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { normalize } from "normalizr";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import initReactFastclick from "react-fastclick";
import MainContext from "./contexts/MainContext";
import MediaContext from "./contexts/MediaContext";
import userContestModel from "./models/userContest";
import UserContestContext from "./contexts/UserContestContext";
import Dashboard from "./pages/Dashboard";
import VerifyPhone from "./pages/VerifyPhone";
import Login from "./pages/Login";
import Rules from "./pages/Rules";
import Activity from "./pages/Activity";
import Activities from "./pages/Activities";
import Account from "./pages/Account";
import Offers from "./pages/Offers";
import Offer from "./pages/Offer";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Game from "./pages/Game";
import Games from "./pages/Games";
import Stats from "./pages/Stats";
import Bars from "./pages/Bars";
import Kids from "./pages/Kids";
import VerifyEmail from "./pages/VerifyEmail";
import EmailVerified from "./pages/EmailVerified";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import PassportSignUp from "./pages/PassportSignUp";
import FormFields from "./pages/FormFields";
import ScoreGuess from "./pages/ScoreGuess";
import StartingLineup from "./pages/StartingLineup";
import StarsOfGame from "./pages/StarsOfGame";
import PassportAbout from "./pages/PassportAbout";
import RosterPollShare from "./pages/RosterPollShare";
import ErrorBoundary from "./components/ErrorBoundary";
import CookieBanner from "./components/CookieBanner";
import Loading from "./components/Loading";
import history from "./history";
import fbPixel from "./services/facebookPixel";
import ENV from "./constants";
import socialPostImage from "./assets/images/social_post.png";
import favicon from "./assets/images/favicon.png";
import "./App.scss";
import Api from "./api";
import { param } from "./services/utils";
import storage from "./services/storage";
import PassportConnect from "./pages/PassportConnect";
import PassportSignInRedirect from "./pages/PassportSignInRedirect";

const STORAGE_OPTIONS = {
  namespace: "kidsclub",
  storages: ["local"],
};

initReactFastclick();

window.setHubValidTicket = () => {};

export function App(props) {
  const [tracked, setTracked] = useState(false);

  const [session, setSession] = useState();
  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionErrors, setSessionErrors] = useState();
  const [sessionComplete, setSessionComplete] = useState(false);

  const [activeMedia, setActiveMedia] = useState(null);
  const [activityList, setActivityList] = useState();

  const [listSubscription, setListSubscription] = useState();
  const [listSubscriptionLoading, setListSubscriptionLoading] = useState(false);
  const [listSubscriptionComplete, setListSubscriptionComplete] =
    useState(false);

  const [userContestData, setUserContestData] = useState({});
  const [userContestsLoading, setUserContestsLoading] = useState(false);
  const [userContestsComplete, setUserContestsComplete] = useState(false);

  const fetchActivityList = useCallback(async (id) => {
    const res = await Api.get(`/activity_lists/${id}`);
    setActivityList(res.activity_list);
  }, []);

  const fetchUserContest = useCallback(async (id, params) => {
    try {
     let path = `user_contests/${id}`;
     if (params) path += `?${param(params)}`;
     const res = await Api.get(path);
     const normalized = normalize(res.user_contest, userContestModel);
     setUserContestData((prev) => {
       return Object.assign({}, prev, normalized.entities);
     });
     return normalized.entities; 
    } catch (error) {
      // silently fail if no user contest
    }
  }, []);

  const fetchUserContests = useCallback(async (params) => {
    try {
      const res = await Api.get(`/user_contests?${param(params)}`);
      const normalized = normalize(res.user_contests, [userContestModel]);
      setUserContestData((prev) => {
        return Object.assign({}, prev, normalized).entities;
      });
      return normalized.entities;
    } catch (error) {
      // silently fail if no user contests
    }
  }, []);

  // used when switching users
  // ensure only relevaant user contest are seen
  const clearUserContests = useCallback(() => {
    setUserContestData({});
  }, []);

  const createUserContest = useCallback(async (body) => {
    const res = await Api.post("/user_contests", body);
    const normalized = normalize(res.user_contest, userContestModel);
    setUserContestData((prev) => {
      return Object.assign({}, prev, normalized).entities;
    });
    return normalized.entities;
  }, []);

  const getUserContest = useCallback(
    (id) => {
      return userContestData?.user_contests?.[id];
    },
    [userContestData]
  );

  const getUserContestAction = useCallback(
    (id) => {
      return userContestData?.user_contest_actions?.[id];
    },
    [userContestData]
  );

  const createListSubscription = (body) => {
    setListSubscriptionLoading(true);
    Api.post("/list_subscriptions", body)
      .then((res) => {
        setListSubscription(res.list_subscription);
      })
      .finally(() => {
        setListSubscriptionLoading(true);
      });
  };

  const fetchListSubscription = () => {
    setListSubscriptionLoading(true);
    Api.get(`/list_subscriptions?list_id=${ENV.ACTIVITY_LIST_ID}`)
      .then((res) => {
        setListSubscription(res.list_subscriptions[0]);
      })
      .finally(() => {
        setListSubscriptionLoading(false);
        setListSubscriptionComplete(true);
      });
  };

  const updateListSubscription = (id, body) => {
    return Api.put(`/list_subscriptions/${id}`, body).then((res) => {
      setListSubscription(res.list_subscription);
    });
  };

  const fetchSession = () => {
    setSessionLoading(true);
    setSessionErrors(null);
    Api.get(`/sessions`)
      .then((res) => {
        setSession(res);
      })
      .catch((err) => {
        setSessionErrors(err);
      })
      .finally(() => {
        setSessionLoading(false);
        setSessionComplete(true);
      });
  };

  const createSession = (body) => {
    Api.post("sessions", {
      session: body,
    })
      .then(() => {
        fetchSession();
      })
      .catch((err) => setSessionErrors(err));
  };

  const deleteSession = () => {
    Api.delete("sessions").then(() => {
      setSession();
      // remove active kid on logout
      storage.remove("active_kid", STORAGE_OPTIONS);
      window.location.reload();
    });
  };

  useEffect(() => {
    fetchActivityList(ENV.ACTIVITY_LIST_ID);
  }, []);

  useEffect(() => {
    fetchSession();
  }, []);

  useEffect(() => {
    if (sessionComplete && !tracked) {
      fbPixel.trackSingle("PageView");
      setTracked(true);
    }
  }, [sessionComplete, tracked]);

  useEffect(() => {
    if (session?.id) {
      fetchListSubscription();
    }
  }, [session?.id]);

  const title = "Bluenatics";
  const description =
    "Take part in fun activities, enter to win great prizes, receive rewards and offers, and much more! Exclusively for Blues fans.";
  if (!sessionComplete || (session?.id && !listSubscriptionComplete)) {
    return (
      <ErrorBoundary component="App">
        <Loading className="App__loading" />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary component="App">
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href={favicon} />
        <meta name="googlebot" content="noindex" />
        <meta property="fb:app_id" content="116527605044984" />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`http://${ENV.APP_DOMAIN}${socialPostImage}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:image"
          content={`http://${ENV.APP_DOMAIN}${socialPostImage}`}
        />
        <meta name="twitter:site" content="@StLouisBlues" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div className="app-container">
        <MainContext.Provider
          value={{
            session,
            sessionLoading,
            sessionComplete,
            sessionErrors,
            sessionReload: fetchSession,
            sessionUpdate: setSession,
            sessionDelete: deleteSession,
            sessionCreate: createSession,

            listSubscription,
            listSubscriptionLoading,
            listSubscriptionComplete,
            listSubscriptionUpdate: updateListSubscription,
            listSubscriptionCreate: createListSubscription,
            listSubscriptionReload: fetchListSubscription,

            activityList: activityList,
            activityListReload: fetchActivityList,
          }}
        >
          <UserContestContext.Provider
            value={{
              userContestData,
              userContestsLoading,
              userContestsComplete,
              fetchUserContests,
              fetchUserContest,
              getUserContest,
              getUserContestAction,
              createUserContest,
              clearUserContests,
            }}
          >
            <MediaContext.Provider
              value={{
                active: activeMedia,
                setActive: setActiveMedia,
                isActive: (id) => activeMedia?.id === id,
              }}
            >
              <Router history={history}>
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/offers" component={Offers} />
                  <Route exact path="/offer/:id" component={Offer} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/sign-in" component={SignIn} />
                  <Route exact path="/sign-up" component={SignUp} />
                  <Route exact path="/passport" component={PassportSignUp} />
                  <Route
                    exact
                    path="/passport-connect"
                    component={PassportConnect}
                  />
                  <Route
                    exact
                    path="/passport-redirect"
                    component={PassportSignInRedirect}
                  />

                  <Route
                    exact
                    path="/passport-about"
                    component={PassportAbout}
                  />
                  <Route exact path="/verify-email" component={VerifyEmail} />
                  <Route exact path="/verify-phone" component={VerifyPhone} />
                  <Route
                    exact
                    path="/email-verified"
                    component={EmailVerified}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route exact path="/form-fields" component={FormFields} />
                  <Route exact path="/rules/:id" component={Rules} />
                  <Route exact path="/activity/:id" component={Activity} />
                  <Route exact path="/activities" component={Activities} />
                  <Route exact path="/score-guess/:id" component={ScoreGuess} />
                  <Route
                    exact
                    path="/starting-lineup/:id"
                    component={StartingLineup}
                  />
                  <Route
                    exact
                    path="/stars-of-game/:id"
                    component={StarsOfGame}
                  />
                  <Route
                    exact
                    path="/roster-poll-share/:id"
                    component={RosterPollShare}
                  />
                  <Route exact path="/games" component={Games} />
                  <Route exact path="/game/:id" component={Game} />
                  <Route exact path="/stats" component={Stats} />
                  <Route exact path="/bars" component={Bars} />
                  <Route exact path="/kids" component={Kids} />
                  <Redirect to="/" />
                </Switch>
              </Router>
              {sessionComplete && (
                <CookieBanner sessionFetched={sessionComplete} />
              )}
            </MediaContext.Provider>
          </UserContestContext.Provider>
        </MainContext.Provider>
      </div>
    </ErrorBoundary>
  );
}

export default App;
