import React, { useContext, useEffect, useState, useCallback } from "react";
import useAgeVerification from "../../hooks/useAgeVerification";
import { get, isEmbeddedWebView } from "../../services/utils";
import { redirectToSignIn } from "../../services/redirect";
import promotionService from "../../services/promotion";
import TS from "../../api";
import MainContext from "../../contexts/MainContext";
import UserContestContext from "../../contexts/UserContestContext";
import { ArrowLeftIcon } from '@heroicons/react/outline'

const LinkClickButton = ({ activity, context, setContext, className, children }) => {
  const { session, listSubscription } = useContext(MainContext);
  const {
    fetchUserContest,
    getUserContest, 
    getUserContestAction
  } = useContext(UserContestContext);
  const contestAction = activity.contest_actions[0];
  const userContest = getUserContest(activity.id);
  const userContestAction = getUserContestAction(contestAction?.id);

  const [iframeVisible, setIframeVisible] = useState(false);
  const promotionId = promotionService.getPromotion();
  const [click, setClick] = useState();
  const contentUrl = get(contestAction, "content.url");
  const contentIframe = get(contestAction, "content.iframe");

  const url = (() => {
    let ret = contentUrl;
    ret = ret.replace("{{user_id}}", get(listSubscription, "user_id"));
    ret = ret.replace("{{subscription_id}}", get(listSubscription, "id"));
    ret = ret.replace("{{first_name}}", get(session, "profile.first_name"));
    ret = ret.replace("{{last_name}}", get(session, "profile.last_name"));
    ret = ret.replace("{{email}}", get(session, "profile.email"));
    ret = ret.replace("{{phone}}", get(session, "profile.phone"));
    return ret;
  })();

  const displayUrl = (() => {
    let x = contentUrl ? contentUrl.split("?")[0].slice(0, 60) : "";
    if (x.length === 60) {
      x += "...";
    }
    return x;
  })();

  const completeClick = useCallback((birthday) => {
    let path = `/link_clicks`;
    TS.post(path, {
      link_click: {
        contest_id: activity.id,
        contest_action_id: contestAction.id,
        promotion_id: promotionId,
      },
    })
      .then(() => {
        setClick(true);
        if (contentIframe) {
          setIframeVisible(true);
        } else if (isEmbeddedWebView()) {
          window.location.href = url;
        }
      })
      .catch(() => {});
    if (!isEmbeddedWebView() && !contentIframe) {
      window.open(url);
    }
  }, []);

  const onClick = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!session || !listSubscription) {
      return redirectToSignIn({
        cb: "linkClick",
        cbid: activity.id,
      });
    }
    
    completeClick();
  };

  // on complete tell parent
  useEffect(() => {
    if (click) {
      if (!userContest && !context) {
        let params = {};
        fetchUserContest(activity.id, params);
      }
    }
    if (click && context) {
      setContext({ working: false, complete: true, disabled: false });
    }
  }, [click]);
  
  if (iframeVisible) {
    return (
      <div className="h-screen w-screen fixed top-0 left-0 bg-black flex flex-col items-center" style={{ zIndex: 1200, paddingBottom: 'env(safe-area-inset-bottom)' }}>
        <div className="p-4">
          <button className="text-xl font-bold text-white flex items-center space-x-1" onClick={() => setIframeVisible(false)}>
            <ArrowLeftIcon className="w-5 h-5 mb-1" />
            <span>BACK TO BLUENATICS</span>
          </button>
        </div>
        <div className="relative w-full pb-[155%] sm:pb-0 sm:w-[500px] sm:h-[775px]">
          <iframe
            src={url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="IFrame"
            className="absolute top-0 left-0 w-full h-full"
          />
        </div>
      </div>
    );
  }

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default LinkClickButton;
