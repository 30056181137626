import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Menu from '../../components/Menu';
import ActivityGrid from '../../components/ActivityGrid';
import ActivityCard from '../../components/ActivityCard';
import NavigationHeader from '../../components/NavigationHeader';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import TS from '../../api';
import MainContext from '../../contexts/MainContext';
import UserContestContext from '../../contexts/UserContestContext';
import LinkClickButton from "../../components/LinkClickButton";
import ENV from '../../constants';
import GameDay from '../../components/GameDay';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import MustBeSubscribed from "../../components/MustBeSubscribed";

const daysies = {
  development: [
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Petal Pluck","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNhMzUwNzU2YTAwMDEzOWZlMjktMC1pbWFnZSJdXQ?sha=c83444d738fcebb6","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Scramble","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNhYzUwNzU2YTAwMDEzOWZlMmItMC1pbWFnZSJdXQ?sha=e2bb295bcad7a7d1","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Trivia","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNiMzUwNzU2YTAwMDEzOWZlMmQtMC1pbWFnZSJdXQ?sha=8146070e4d66457f","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Lie Detector","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNiYzUwNzU2YTAwMDEzOWZlMmYtMC1pbWFnZSJdXQ?sha=880befb9fff3cca6","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Crossword","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNjNDUwNzU2YTAwMDEzOWZlMzEtMC1pbWFnZSJdXQ?sha=edd132bf00eddc43","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Word Search","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNjNjYjUwNzU2YTAwMDEzOWZlMzMtMC1pbWFnZSJdXQ?sha=a856ef46afee6ca5","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
  ],
  staging: [
    {"id":"66c626cf21f5f80001525471","type":"Activity","title":"Play Petal Pluck","supertitle":"DAILY GAMES","subtitle":"Can you figure out the St. Louis Blues-related word or phrase without losing all your petals?","enrolled_subtitle":null,"starts_at":"2024-08-21T13:41:00.000+00:00","ends_at":"2024-11-11T13:41:00.000+00:00","og_title":"Play Petal Pluck","og_description":null,"btn_label":"PLAY","header_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjZjNjI2Y2YyMWY1ZjgwMDAxNTI1NDcxLTE3MjQyNjMwMzYtaGVhZGVyIl1d?sha=1896d6b92e0414f2","shareable_facebook_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjZjNjI2Y2YyMWY1ZjgwMDAxNTI1NDcxLTE3MjQyNjMwMzYtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=9e720aaa21495be0","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"66c626e621f5f80001525478","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.stagesies.com/topic/66be4943e37f2a003c0ac4a4/petalpluck?external_ref=bluenatics:{{user_id}}","id":"66c6260521f5f8000152546b"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]}
  ],
  production: [
    {"id":"670d885a50756a00013a0b57","type":"Activity","title":"Play Petal Pluck","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:08:00.000+00:00","ends_at":null,"og_title":"Play Petal Pluck","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg4NWE1MDc1NmEwMDAxM2EwYjU3LTAtaGVhZGVyIl1d?sha=d65b1ca293a7bc68","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg4NWE1MDc1NmEwMDAxM2EwYjU3LTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=7951b7fa91e1aee6","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d888250756a00013a0b5f","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesPetalPluck\u0026utm_id=BluesPilot","id":"670d86fa50756a00013a0b37"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670d88a750756a00013a0b63","type":"Activity","title":"Play Scramble","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:09:00.000+00:00","ends_at":null,"og_title":"Play Scramble","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg4YTc1MDc1NmEwMDAxM2EwYjYzLTE3Mjg5NDAxOTktaGVhZGVyIl1d?sha=009a7b2d15d5990c","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg4YTc1MDc1NmEwMDAxM2EwYjYzLTE3Mjg5NDAxOTktZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=c9c094c95c78e6ce","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d88de50756a00013a0b6b","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Scramble","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/scramble?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesScramble\u0026utm_id=BluesPilot","id":"670d871a50756a00013a0b39"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670d894b50756a00013a0b7d","type":"Activity","title":"Play Trivia","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:12:00.000+00:00","ends_at":null,"og_title":"Play Trivia","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5NGI1MDc1NmEwMDAxM2EwYjdkLTAtaGVhZGVyIl1d?sha=eac7d73bfb5e12f2","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5NGI1MDc1NmEwMDAxM2EwYjdkLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=a3c26916bc30d657","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d895850756a00013a0b85","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Trivia","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/trivia?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesTrivia\u0026utm_id=BluesPilot","id":"670d874c50756a00013a0b3d"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670d89b150756a00013a0b95","type":"Activity","title":"Play Lie Detector","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:14:00.000+00:00","ends_at":null,"og_title":"Play Lie Detector","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5YjE1MDc1NmEwMDAxM2EwYjk1LTAtaGVhZGVyIl1d?sha=a661719492897e67","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5YjE1MDc1NmEwMDAxM2EwYjk1LTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=ca055d8e207db55a","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d89c650756a00013a0b9d","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Lie Detector","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/liedetector?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesLieDetector\u0026utm_id=BluesPilot","id":"670d877950756a00013a0b41"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670d891750756a00013a0b71","type":"Activity","title":"Play Crossword","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:11:00.000+00:00","ends_at":null,"og_title":"Play Crossword","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5MTc1MDc1NmEwMDAxM2EwYjcxLTAtaGVhZGVyIl1d?sha=5ef848b74575b0ae","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5MTc1MDc1NmEwMDAxM2EwYjcxLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=06900e66895f5236","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d892350756a00013a0b79","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Crossword","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/crossword?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesCrossword\u0026utm_id=BluesPilot","id":"670d873550756a00013a0b3b"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
    {"id":"670d898050756a00013a0b89","type":"Activity","title":"Play Word Search","supertitle":"DAILY GAMES","subtitle":"Daysies are games that update daily based on your topic of choice. Test your Blues knowledge every day with games that include Trivia, Petal Pluck, Crossword, Word Search, Scrambles and Lie Detector. Daysies are created with the help of AI.","enrolled_subtitle":null,"starts_at":"2024-10-14T17:13:00.000+00:00","ends_at":null,"og_title":"Play Word Search","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5ODA1MDc1NmEwMDAxM2EwYjg5LTAtaGVhZGVyIl1d?sha=e64d3dc840372dc6","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDg5ODA1MDc1NmEwMDAxM2EwYjg5LTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=62182d523911a4ef","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d898c50756a00013a0b91","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Word Search","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/wordsearch?external_ref=bluenatics:{{user_id}}\u0026utm_source=bluenatics\u0026utm_medium=embed\u0026utm_campaign=BluesWordSearch\u0026utm_id=BluesPilot","id":"670d875f50756a00013a0b3f"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
  ]
};
daysies.beta = daysies.production;

// const arcade = {
//   development: [
//     {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Fly Puck","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNWJjYTUwNzU2YTAwMDEzOWZkYTEtMC1pbWFnZSJdXQ?sha=dbd44b8c6bc15f19","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Hockey Pong","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNWJiMjUwNzU2YTAwMDEzOWZkOWEtMC1pbWFnZSJdXQ?sha=48f83e734657646f","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"670ac6748f4a5a0011d1a42a","type":"Activity","title":"Play Hockey Rush","starts_at":"2024-10-12T13:56:00.000+00:00","ends_at":"2024-10-15T13:56:00.000+00:00","header_photo":"https://api.tunespeak.com/images/W1siZiIsImltYWdlcy82NzBkNWJiZTUwNzU2YTAwMDEzOWZkOWQtMC1pbWFnZSJdXQ?sha=ce7d719536a0f028","contest_actions":[{"id":"670ac6db8f4a5a0011d1a42e","template":"link-click","position":null,"content":{"btn_label":"Petal Pluck","description":"DESCRIPTION","icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Petal Pluck","type":"LinkClickPrompt","url":"https://www.daysies.com/topic/66327f8944f7c4fadecdf462/petalpluck","id":"670ac6598f4a5a0011d1a426"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//   ],
//   staging: [
//     {"id":"670003edf17bfc000164ee8d","type":"Activity","title":"Play Hockey Pong","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-10-04T11:04:00.000+00:00","ends_at":null,"og_title":"Play Hockey Pong","og_description":null,"btn_label":"PLAY","header_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAzZWRmMTdiZmMwMDAxNjRlZThkLTAtaGVhZGVyIl1d?sha=e6da78634da9c9a6","shareable_facebook_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAzZWRmMTdiZmMwMDAxNjRlZThkLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=106a1773651de90a","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670003fef17bfc000164ee95","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Hockey Pong","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709146878089","id":"670003c1f17bfc000164ee89"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"67000345f17bfc000164ee7d","type":"Activity","title":"Play Hockey Rush","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-10-04T11:01:00.000+00:00","ends_at":null,"og_title":"Play Hockey Rush","og_description":null,"btn_label":"PLAY","header_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAzNDVmMTdiZmMwMDAxNjRlZTdkLTAtaGVhZGVyIl1d?sha=b866bc1503aad316","shareable_facebook_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAzNDVmMTdiZmMwMDAxNjRlZTdkLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=690eabb96dbb065f","auto_enroll":true,"has_list_offer":false,"minimum_age":13,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"6700036df17bfc000164ee85","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Hockey Rush","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709147161069","id":"670002fcf17bfc000164ee79"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"670001b3f17bfc000164ee6b","type":"Activity","title":"Play Fly Puck","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-10-04T10:54:00.000+00:00","ends_at":null,"og_title":"Play Fly Puck","og_description":null,"btn_label":"PLAY","header_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAxYjNmMTdiZmMwMDAxNjRlZTZiLTAtaGVhZGVyIl1d?sha=3dae8ed9cb1f509e","shareable_facebook_photo":"https://api.stagingspeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwMDAxYjNmMTdiZmMwMDAxNjRlZTZiLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=e92fe8972c8ba60f","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670002acf17bfc000164ee75","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Fly Puck","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709147732231","id":"670002a1f17bfc000164ee71"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//   ],
//   production: [
//     {"id":"670d8a1050756a00013a0ba3","type":"Activity","title":"Play Fly Puck","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-10-14T17:15:00.000+00:00","ends_at":null,"og_title":"Play Fly Puck","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDhhMTA1MDc1NmEwMDAxM2EwYmEzLTAtaGVhZGVyIl1d?sha=9dbc734129aff1cd","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDhhMTA1MDc1NmEwMDAxM2EwYmEzLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=3e07f37080071367","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d8a1d50756a00013a0bab","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Fly Puck","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709216115839","id":"670d87d850756a00013a0b47"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"65f09d50a7debc0001d69270","type":"Activity","title":"Play Hockey Pong","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-03-12T14:21:00.000+00:00","ends_at":"2024-04-19T04:59:00.000+00:00","og_title":"Play Hockey Pong","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjVmMDlkNTBhN2RlYmMwMDAxZDY5MjcwLTAtaGVhZGVyIl1d?sha=4a9cf776e75474fd","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjVmMDlkNTBhN2RlYmMwMDAxZDY5MjcwLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=10992b6993a2fb66","auto_enroll":true,"has_list_offer":false,"minimum_age":13,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"65f09d70a7debc0001d69277","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Hockey Pong","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709215808476","id":"65f09c42a7debc0001d69262"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//     {"id":"670d8a4050756a00013a0bb1","type":"Activity","title":"Play Hockey Rush","supertitle":"ARCADE","subtitle":null,"enrolled_subtitle":null,"starts_at":"2024-10-14T17:16:00.000+00:00","ends_at":null,"og_title":"Play Hockey Rush","og_description":null,"btn_label":"PLAY","header_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDhhNDA1MDc1NmEwMDAxM2EwYmIxLTAtaGVhZGVyIl1d?sha=60f0bd7e9813c0e4","shareable_facebook_photo":"https://api.tunespeak.com/images/W1siZiIsImFjdGl2aXRpZXMvNjcwZDhhNDA1MDc1NmEwMDAxM2EwYmIxLTAtZmFjZWJvb2tfcG9zdCJdLFsicCIsImVuY29kZSIsImpwZyJdXQ.jpg?sha=69b4341efba32ffd","auto_enroll":true,"has_list_offer":false,"minimum_age":18,"verify_bday":false,"require_accounts":null,"contest_actions":[{"id":"670d8a4d50756a00013a0bb9","template":"link-click","position":null,"content":{"btn_label":"PLAY","description":null,"icon":"icon_link","iframe":true,"photo":null,"reclickable":true,"title":"Play Hockey Rush","type":"LinkClickPrompt","url":"https://playspark.co/ad/1709215973096","id":"670d87c150756a00013a0b45"},"group_template":null,"group_id":null,"hidden_at":null,"point_value":null,"direct_factor":1,"indirect_factor":3,"direct_limit":1,"direct_time_limit":null,"total_direct_limit":1,"requires_user":true,"write_inable":null}],"sponsors":[]},
//   ]
// };
// arcade.beta = arcade.production;

const Games = (props) => {
  const { session, listSubscription } = useContext(MainContext);
  const { fetchUserContests } = useContext(UserContestContext);
  const { display } = useParams(props);
  const [gameDay, setGameDay] = useState();
  const [game, setGame] = useState();

  useEffect(() => {
    if (gameDay) {
      TS.get(`/games/${gameDay.game_id}`).then(res => {
        setGame(res.game);
      });
    }
  }, [gameDay?.game_id]);

  useEffect(() => {
    if (session?.id && game?.id) {
      const ids = game.game_day_listings.map((listing) => listing.listable.id).join(',');
      fetchUserContests({ contest_ids: ids });
    }
  }, [session?.id, game?.id]);

  useEffect(() => {
    if (session || listSubscription) {
      TS.get(`/game_days/today?list_id=${ENV.ACTIVITY_LIST_ID}`).then((res) => {
        if (res.game_days) setGameDay(res.game_days[0]);
      });
    }
  }, [session, listSubscription]);

  return (
    <MustBeSubscribed>
      <Menu
        path={props.location.pathname}
        logo={display}
      />
      {display !== 'app' && (
        <NavigationHeader path={props.location.pathname} />
      )}

      <div className="Games max-w-3xl w-full mx-auto min-h-screen py-4 sm:py-6 space-y-6 px-4">
        <div>
          <div className="nhl-medium text-3xl uppercase pb-3">Daysies</div>
          <p className="sm:text-lg leading-tight">New games every day! Show off how well you know your favorite team by starting your day with these Blues-themed trivia and word games!</p>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-6 mt-6">
            {daysies[ENV.env].map((activity) => (
              <LinkClickButton
                activity={activity}
                className="bg-black text-white rounded shadow-lg overflow-hidden flex flex-col gap-y-2 items-center hover:opacity-90"
                title={activity.title}
              >
                <img
                  src={activity.header_photo}
                  alt={activity.title}
                />
              </LinkClickButton>
            ))}
          </div>
        </div>
        {/* <div>
          <div className="nhl-medium text-3xl uppercase pb-3">Arcade Games</div>
          <div className="grid sm:grid-cols-3 gap-6 mt-3">
            {arcade[ENV.env].map((activity) => (
              <LinkClickButton
                activity={activity}
                className="bg-black text-white rounded shadow-lg overflow-hidden flex flex-col gap-y-2 items-center hover:opacity-90"
                title={activity.title}
              >
                <img
                  src={activity.header_photo}
                  alt={activity.title}
                />
              </LinkClickButton>
            ))}
          </div>
        </div> */}

        {gameDay && (
          <>
            <div>
              <div className="flex items-center justify-between pb-3">
                <div className="nhl-medium text-3xl uppercase">Today's Game</div>
                <Link
                  to={"/stats"}
                  className="link uppercase flex items-center text-2xl nhl-regular"
                >
                  Past Games & Stats
                  <span className="bg-white p-0.5 flex justify-center items-center rounded-full ml-2">
                    <ChevronRightIcon className="h-4 w-4" />
                  </span>
                </Link>
              </div>
              <div className="space-y-3">
                <GameDay activity={gameDay} className="shadow-lg" showLink={false} />
              </div>
            </div>
        
            <ActivityGrid>{game?.game_day_listings?.map(listing => <ActivityCard key={listing.listable.id} activity={listing.listable} />)}</ActivityGrid>
          </>
        )}

        
        <div className="flex items-center justify-between">
          <Link
            to={"/"}
            className="mt-6 mb-5 link uppercase flex items-center text-2xl nhl-regular"
          >
            <span className="bg-white p-0.5 flex justify-center items-center rounded-full mr-2">
              <ChevronLeftIcon className="h-4 w-4" />
            </span>
            Return to Home
          </Link>

          <Link
            to={"/stats"}
            className="mt-6 mb-5 link uppercase flex items-center text-2xl nhl-regular"
          >
            Past Games & Stats
            <span className="bg-white p-0.5 flex justify-center items-center rounded-full ml-2">
              <ChevronRightIcon className="h-4 w-4" />
            </span>
          </Link>
        </div>
      </div>

      {display !== 'app' && (
        <Fragment>
          <Footer />
          <BluesFooter />
        </Fragment>
      )}
    </MustBeSubscribed>
  )
}

export default Games;